import { useSearchParams } from "react-router-dom";

import { signal } from "@preact/signals-react";

export const clientUniquePath = signal<string | undefined | null>(undefined);

export function useClientAuth() {
  const [searchParams] = useSearchParams();
  const enc = searchParams.get("enc");

  clientUniquePath.value = enc;

  return enc;
}
