import React from "react";
import { NavLink } from "react-router-dom";

import { googleConsentURL } from "../../interfaces/backend/auth";
import { isAuthValid } from "../../interfaces/states/auth/auth";
import { pwaInstallEvent } from "../../utilities/hooks/usePwaInstall";
import { CONFIG } from "../../utilities/variables/config";
import { Logo } from "../_atoms/Logo";
import { SVG } from "../_atoms/SVG";
import { AccountMenu } from "./AccountMenu";
import { Links } from "./Links";

export function NavBar() {
  // const { pathname } = useLocation();

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-content">
          <div className="layout-gr">
            {pwaInstallEvent.value ? (
              <SVG
                name="install"
                className="svg-install"
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  pwaInstallEvent.value?.prompt();
                }}
              />
            ) : null}

            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>

          {/* {pathname !== "/workspace" ? ( */}
          <div className="links">
            {isAuthValid.value ? (
              <Links links={CONFIG.authLinks} />
            ) : (
              <Links links={CONFIG.guestLinks} />
            )}
          </div>
          {/*  ) : null} */}

          {!isAuthValid.value ? (
            <NavLink to={googleConsentURL} className="navbar-sign-in-btn">
              Login / Sign up
            </NavLink>
          ) : (
            <div className="selectors">
              {/* <ThemeSelector /> */}

              {/* <div className="search">
                <Select
                  onChange={() => {}}
                  options={[]}
                  placeholder="Search Here..."
                  // width={"100%"}
                  // isSearchable
                  icon="search"
                />
              </div> */}

              <AccountMenu />
            </div>
          )}
        </div>

        {/* <div className="separated-search">
          <Select
            onChange={() => {}}
            options={[]}
            placeholder="Search Here..."
            // width={"100%"}
            // isSearchable
            icon="search"
          />
        </div> */}
      </div>
    </nav>
  );
}
