import React from "react";

import { signal } from "@preact/signals-react";

import imgBuilding1 from "../../../assets/imgs/homepage-section-1-1.png";
import imgBuilding2 from "../../../assets/imgs/homepage-section-1-2.png";
import imgBuilding3 from "../../../assets/imgs/homepage-section-1-3.png";

export function Section1Preview({ className }: TypePropsSection1Preview) {
  useCheckImageLoaded();

  const areLoaded = loaded.value.img1 && loaded.value.img2 && loaded.value.img3;

  return areLoaded ? (
    <article className={className}>
      <div className="preview-top" />
      <picture className="preview-1">
        <img src={imgBuilding1} alt="Home and building inspection" />
      </picture>
      <picture className="preview-2">
        <img src={imgBuilding2} alt="Home and building inspection" />
      </picture>
      <picture className="preview-3">
        <img src={imgBuilding3} alt="Home and building inspection" />
      </picture>
    </article>
  ) : (
    <div>Loading...</div>
  );
}

export type TypePropsSection1Preview = {
  className?: string;
};

const loaded = signal<{ img1: boolean; img2: boolean; img3: boolean }>({
  img1: false,
  img2: false,
  img3: false,
});

export function useCheckImageLoaded() {
  React.useEffect(() => {
    const img1 = document.createElement("img");
    img1.src = imgBuilding1;
    img1.onload = () => {
      loaded.value = { ...loaded.value, img1: true };
    };

    const img2 = document.createElement("img");
    img2.src = imgBuilding2;
    img2.onload = () => {
      loaded.value = { ...loaded.value, img2: true };
    };

    const img3 = document.createElement("img");
    img3.src = imgBuilding3;
    img3.onload = () => {
      loaded.value = { ...loaded.value, img3: true };
    };

    return () => {
      img1.onload = null;
      img2.onload = null;
      img3.onload = null;

      img1.remove();
      img2.remove();
      img3.remove();
    };
  }, []);
}
