import React from 'react';

import svgs from '../../assets/svgs/sprite.svg';
import { PROPERTY_ICON_NAME } from '../../utilities/__shared__/types';

export const SVG = React.memo(SVGRaw) as typeof SVGRaw;
function SVGRaw({ name, ...props }: TypePropsSVG) {
  return (
    <svg {...props}>
      <use href={svgs + "#icon-" + name} />
    </svg>
  );
}

export type TypePropsSVG = React.SVGProps<SVGSVGElement> & {
  name: TypeGeneralSVGs | TypeBuildingSVGs | TypePropertyTypes;
};

export type TypePropertyTypes =
  | PROPERTY_ICON_NAME.CONDO
  | PROPERTY_ICON_NAME.COMMERCIAL
  | PROPERTY_ICON_NAME.OTHER
  | PROPERTY_ICON_NAME.SINGLE_FAMILY_ATTACHED
  | PROPERTY_ICON_NAME.SINGLE_FAMILY_DETACHED
  | PROPERTY_ICON_NAME.TOWNHOUSE;

export type TypeBuildingSVGs =
  | "appliance-1"
  | "appliance-2"
  | "basement"
  | "bathroom-1"
  | "bathroom-2"
  | "bedroom"
  | "brick"
  | "cooling"
  | "dining-room"
  | "door"
  | "electrical"
  | "exterior"
  | "fireplace"
  | "foundation-1"
  | "foundation-2"
  | "garage"
  | "hardhat"
  | "heating"
  | "house-2"
  | "house-3"
  | "house-1"
  | "kitchen"
  | "laundry"
  | "library"
  | "living-room"
  | "office"
  | "plumbing"
  | "pool"
  | "report"
  | "roof"
  | "siding"
  | "ventilation"
  | "window"
  | "wrench"
  | "yard";

export type TypeGeneralSVGs =
  | "add"
  | "agreement"
  | "agreement-2"
  | "agreement-3"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "attach"
  | "calendar"
  | "check-accept"
  | "clock"
  | "close"
  | "close-2"
  | "contact-us"
  | "copy"
  | "danger"
  | "dashboard"
  | "delete"
  | "doc-2"
  | "doc"
  | "dots"
  | "download"
  | "edit-2"
  | "edit"
  | "email"
  | "export"
  | "facebook"
  | "file"
  | "footer"
  | "inspection"
  | "instagram"
  | "install"
  | "linkedin"
  | "list"
  | "location-2"
  | "location"
  | "lock"
  | "magic"
  | "message"
  | "money-2"
  | "money"
  | "note-sticky"
  | "opened-msg"
  | "pdf"
  | "phone-particle-1"
  | "phone-particle-2"
  | "phone"
  | "recycle"
  | "schedule"
  | "search"
  | "select"
  | "sent-msg"
  | "settings"
  | "share-2"
  | "share-3"
  | "share"
  | "sort"
  | "task"
  | "twitter"
  | "upload"
  | "user";
