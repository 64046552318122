import throttle from 'lodash.throttle';
import React from 'react';

import { computed, signal } from '@preact/signals-react';

import { dummyFunction } from '../../utilities/functions';

export const windowResizeEvent = signal<UIEvent | undefined>(undefined);

export const windowInnerWidth = computed(() => {
  dummyFunction(windowResizeEvent.value);

  return window.innerWidth;
});

function windowResizeHandlerRaw(event: UIEvent) {
  windowResizeEvent.value = event;
}

const windowResizeHandler = throttle(windowResizeHandlerRaw, 300, {
  leading: true,
  trailing: true,
});

export function useWindowResize() {
  React.useEffect(() => {
    window.addEventListener("resize", windowResizeHandler);

    return function () {
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, []);
}
