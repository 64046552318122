import type { TypePropsLinks } from "../../components/_molecules/Links";

export enum ENVs {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
}

const env = (process.env.REACT_APP_ENV ?? ENVs.DEVELOPMENT) as ENVs;

const config: TypeConfig = {
  // apiBaseUrl: process.env.REACT_APP_SMARTDIFY_API ?? "",
  // filesBaseURL: process.env.REACT_APP_FILES_URL ?? "",

  TINY_MCE_API_KEY: "2pkrna8davv22h8j1muvek6gpquzrpomwnbd96wjik30dq0r",

  OPEN_AI_API_KEY: "sk-proj-NP6Xbz29XFBIxnci9SPQT3BlbkFJUP3rzGN8Ikl5zvSjwEvY",

  authLinks: [
    { label: "Workspace", to: "/workspace" },
    // TODO:

    { label: "Inspections list", to: "/inspections" },

    { label: "Templates", to: "/templates" },

    // { label: "Contacts", to: "/contacts" },
    { label: "Settings", to: "/settings" },
    // { label: "Education", to: "/education" },
  ],

  guestLinks: [
    // { label: "About Us", to: "/about" },
    // { label: "Contact Us", to: "/contact" },
    // { label: "Pricing", to: "/pricing" },
  ],

  sidebarLinks: [
    { label: "Workspace", to: "/workspace" },
    // TODO:

    { label: "Inspections list", to: "/inspections" },

    { label: "Templates", to: "/templates" },

    // { label: "Contacts", to: "/contacts" },
    { label: "Settings", to: "/settings" },
    // { label: "Education", to: "/education" },
  ],

  imgPlaceholders: {
    default: "https://via.placeholder.com/480",
  },

  dateFormat: "MMM dd, yyyy",

  ...{
    [ENVs.DEVELOPMENT]: {
      apiBaseUrl: "http://localhost:8080/v1",
      filesBaseURL: "https://smartdify-staging-files.s3.amazonaws.com/",
      REACT_APP_GOOGLE_MAPS_API_KEY: "AIzaSyCAite3lZj-7Ss4NZlkkji5HHpmr1wt9oA",
    },

    [ENVs.STAGING]: {
      apiBaseUrl: "https://staging.api.smartdify.com/v1",
      filesBaseURL: "https://smartdify-staging-files.s3.amazonaws.com/",
      REACT_APP_GOOGLE_MAPS_API_KEY: "AIzaSyCAite3lZj-7Ss4NZlkkji5HHpmr1wt9oA",
    },

    [ENVs.PRODUCTION]: {
      apiBaseUrl: "https://api.smartdify.com/v1",
      filesBaseURL: "https://smartdify-staging-files.s3.amazonaws.com/",
      REACT_APP_GOOGLE_MAPS_API_KEY: "AIzaSyCAite3lZj-7Ss4NZlkkji5HHpmr1wt9oA",
    },
  }[env],
};

export const CONFIG = Object.freeze(config);

type TypeConfig = {
  apiBaseUrl: string;

  filesBaseURL: string;

  imgPlaceholders: {
    default: string;
  };

  dateFormat: string;

  TINY_MCE_API_KEY: string;

  OPEN_AI_API_KEY: string;

  authLinks: TypePropsLinks["links"];
  guestLinks: TypePropsLinks["links"];
  sidebarLinks: TypePropsLinks["links"];

  REACT_APP_GOOGLE_MAPS_API_KEY: string;
};
