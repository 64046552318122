export function dummyFunction(...arg: any) {}

export class ObserveIntersect {
  private _elements: NodeListOf<Element> | null = null;
  private _observer = new IntersectionObserver((entries) => {
    this._callback(entries, this);
  }, this._options);

  constructor(
    private readonly _selector: string,

    private readonly _callback: (
      entries: IntersectionObserverEntry[],
      observe: ObserveIntersect
    ) => void,

    private readonly _options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0.01,
    }
  ) {}

  public init() {
    this._elements = document.querySelectorAll(this._selector);
  }

  public get observer() {
    return this._observer;
  }

  public observe() {
    if (!this._elements) this.init();

    if (this._elements)
      this._elements.forEach((element) => this._observer.observe(element));
  }
}
