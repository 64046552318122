import anime from "animejs";
import React from "react";

import { ObserveIntersect } from "../../../utilities/functions";
import { Section2Card } from "../../_molecules/home/Section2Card";

export function Section2() {
  const uid = React.useId();

  return (
    <section className="section-obs section-2">
      <h2 className="section-2-heading">
        <span className="heading-colored">Benefits</span>&nbsp;of Joining
        Smartdify as an Inspector
      </h2>

      <section className="section-2-content">
        {cards.map((card, index) => (
          <Section2Card {...card} index={index + 1} key={index + uid} />
        ))}
      </section>
    </section>
  );
}

const cards = [
  {
    heading: "Get Discovered",
    content:
      "Unlock your potential and get discovered with this cutting-edge inspection report generator, revolutionizing the way you show your expertise and services.",
  },

  {
    heading: "Get Quote Requests",
    content:
      "Get more business with our intuitive inspection report generator platform, empowering you to connect with those seeking your expertise.",
  },

  {
    heading: "Connect with Clients",
    content:
      "Forge meaningful connections with clients using the user-friendly platform, ensuring a seamless and efficient communication process.",
  },
];

export const observe2 = new ObserveIntersect(
  ".section-2",
  ([entry], observe) => {
    if (entry.isIntersecting) {
      observe.observer.unobserve(entry.target);

      anime
        .timeline()
        .add({
          targets: ".section-2",
          opacity: [0, 1],
          translateY: [-100, 0],
          easing: "linear",
          duration: 1000,
          delay: 750,
        })
        .add({
          targets: ".section-2-card",
          opacity: [0, 1],
          easing: "linear",
          duration: 1000,
          delay: (el: any, index) => index * 1000,
        });
    }
  }
);
