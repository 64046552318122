import React from "react";

export function Section2Card({
  heading,
  content,
  index,
}: TypePropsSection2Card) {
  return (
    <article className="section-2-card">
      <div className="card-index-container">
        <div className="card-index-colored-bg" />
        <div className="card-index">{`${index}`.padStart(2, "0")}</div>
      </div>

      <h3 className="card-heading">{heading}</h3>

      <p className="card-content">{content}</p>
    </article>
  );
}

export type TypePropsSection2Card = {
  heading: string;
  content: string;
  index: number;
};
