import React from 'react';

import { signal } from '@preact/signals-react';

export const pwaInstallEvent = signal<any>(undefined);

export function usePwaInstall() {
  React.useEffect(() => {
    const handlerCaptureEvent = (event: any) => {
      event.preventDefault();
      pwaInstallEvent.value = event;
    };

    const handlerCaptureInstalled = () => {
      pwaInstallEvent.value = undefined;
    };

    window.addEventListener("beforeinstallprompt", handlerCaptureEvent);

    window.addEventListener("appinstalled", handlerCaptureInstalled);

    return function () {
      window.removeEventListener("beforeinstallprompt", handlerCaptureEvent);
      window.removeEventListener("appinstalled", handlerCaptureInstalled);
    };
  }, []);
}
