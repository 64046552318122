import React from 'react';

import texts from '../../assets/texts/index.json';

export function Logo() {
  return (
    <div className="logo layout-gr-sb">
      {/* <div
        style={{
          width: "2.4rem",
          height: "2.4rem",
          backgroundColor: "grey",
          marginRight: "0.5rem",
        }}
      /> */}
      <div>{texts.generics.title}</div>
    </div>
  );
}
