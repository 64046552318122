import React from 'react';
import { toast } from 'react-toastify';

import { accessToken } from '../../interfaces/backend/auth';
import { getProfile } from '../../interfaces/backend/profile';
import { openModal } from '../../interfaces/states/modal';
import { CONFIG } from '../../utilities/variables/config';
import { MODALS_NAMES } from '../../utilities/variables/modals';
import { SVG } from '../_atoms/SVG';

export function AccountMenu() {
  const tkn = !!accessToken.value;

  React.useEffect(() => {
    if (!tkn) return;

    getProfile.request({
      variables: {
        where: {},
      },

      onError() {
        toast.error("Failed to load profile");
      },
    });
  }, [tkn]);

  const profilePicture = getProfile.data.value?.profile?.picture;

  return profilePicture ? (
    <picture className="account-user-picture">
      <img
        src={CONFIG.filesBaseURL + profilePicture}
        alt="User"
        onClick={openNavAccountModal}
      />
    </picture>
  ) : (
    <SVG
      name="user"
      className="account-user-picture"
      onClick={openNavAccountModal}
    />
  );
}

function openNavAccountModal() {
  openModal({ name: MODALS_NAMES.ACCOUNT });
}
