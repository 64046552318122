import clsx from "clsx";
import React from "react";

import { dummyFunction } from "../../utilities/functions";

export function InputText({
  text,
  setText = dummyFunction,
  placeholder,
  className,
  title,
  subtitle,
  disabled = false,
  onClick,
  isFloat = false,
  isInteger = false,
  isPositive = false,
}: TypePropsTextEditable) {
  const uid = "txt-" + React.useId();

  return (
    <label
      className={clsx(
        className ? className : "text",
        !disabled && `${className ? className : "text"}-active`
      )}
      onClick={onClick}
      htmlFor={uid}
    >
      <input
        type="text"
        value={text}
        onChange={(e) => {
          const newValue = e.target.value;

          // console.log({ newValue });

          if (!isFloat && !isInteger) return setText(newValue);

          // check regex if isFloat
          const isNumber = isFloat
            ? /^-?\d*\.?\d*$/.test(newValue)
            : isInteger
            ? /^-?\d*$/.test(newValue)
            : false;

          if (!isNumber) return;

          if (isPositive && newValue[0] === "-") return;

          setText(newValue);
        }}
        placeholder={placeholder}
        disabled={disabled}
        id={uid}
      />

      {title || subtitle ? (
        <div className={`${className ? className : "text"}-titles`}>
          {title ? (
            <div className={`${className ? className : "text"}-title`}>
              {title}
            </div>
          ) : null}

          {subtitle ? (
            <div className={`${className ? className : "text"}-subtitle`}>
              {subtitle}
            </div>
          ) : null}
        </div>
      ) : null}
    </label>
  );
}

export type TypePropsTextEditable = {
  text: string | undefined;
  setText?: (text: string | undefined) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  isFloat?: boolean;
  isInteger?: boolean;
  isPositive?: boolean;
};
