import anime from "animejs";
import React from "react";
import { NavLink } from "react-router-dom";

import { ObserveIntersect } from "../../../utilities/functions";
import {
  FooterCard,
  TypePropsFooterCard,
} from "../../_molecules/home/FooterCard";
import { Spacer } from "./Spacer";

export function FooterHome() {
  const uid = React.useId();

  return (
    <footer className="section-obs homepage-footer">
      <Spacer />

      <section className="homepage-footer-content">
        {footers.map((item, index) => (
          <FooterCard {...item} key={index + "-fc-" + uid} />
        ))}
      </section>

      <Spacer />

      <section className="homepage-footer-terms">
        <div className="homepage-footer-divider" />

        <div className="homepage-footer-terms-content">
          <div className="footer-copyright">&copy;&nbsp;Smartdify.com</div>

          <div className="footer-terms">
            {terms.map((name, index) => (
              <NavLink to={"/"} key={index + uid}>
                {name}
              </NavLink>
            ))}
          </div>
        </div>
      </section>
    </footer>
  );
}

const terms = ["Support", "Terms of Use", "Privacy Policy"];

const footers: TypePropsFooterCard[] = [
  {
    title: "Templates",
    items: [
      { label: "Some text" },
      { label: "Some text" },
      { label: "Some text" },
      { label: "Some text" },
    ],
  },

  {
    title: "What is Smartdify?",
    items: [
      { label: "About Us" },
      { label: "Our Solutions" },
      { label: "Our Blog" },
    ],
  },

  {
    title: "Contact Us",
    items: [
      { icon: "email", label: "Smartdify@gmail.com" },
      {
        icon: "location",
        label:
          "300 2240 Chippendale Road, West Vancouver, British Columbia V75 3J5, Canada",
      },
      { icon: "phone", label: "+1(604) 6761414" }
    ],
  },

  {
    title: "Follow Us:",
    items: [
      { icon: "linkedin" },
      { icon: "instagram" },
      { icon: "facebook" },
      { icon: "twitter" },
    ],
    isVertical: false,
  },
];

export const observeFooter = new ObserveIntersect(
  ".homepage-footer",
  ([entry], observe) => {
    if (!entry.isIntersecting) return;
    // observe.observer.unobserve(entry.target);

    anime
      .timeline()
      .add({
        targets: ".homepage-footer-card",
        opacity: [0, 1],
        // translateY: [-100, 0],
        duration: 1000,
        easing: "linear",
        delay: (el: any, index) => 500 + index * 1000,
      })
      .add({
        targets: ".homepage-footer-terms",
        opacity: [0, 1],
        duration: 2000,
        easing: "linear",
        delay: 0,
      });
  }
);
// Remove
