import React from "react";

import { SVG, TypePropsSVG } from "../../_atoms/SVG";

export function Section6Card({ icon, label }: TypePropsSection6Card) {
  return (
    <article className="section-6-card">
      <SVG name={icon} className="card-icon" />
      <h3 className="card-label">{label}</h3>
    </article>
  );
}

export type TypePropsSection6Card = {
  icon: TypePropsSVG["name"];
  label: string;
};
