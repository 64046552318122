import React from "react";

export function Section3Description() {
  return (
    <article className="section-3-description">
      <h3 className="description-title">Pricing</h3>
      <h4 className="description-subtitle">Pay as you go</h4>
      <p className="description-content">
        Pricing diplomacy in this context involves a structured approach to
        determining costs associated with inspections. The rate is set at $5
        every inspector per inspection, resulting in a total cost of $75 for up
        to 15 inspections monthly. This transparent pricing model ensures
        clarity and fairness, allowing clients to easily calculate expenses
        based on the number of inspections required.
      </p>
    </article>
  );
}
