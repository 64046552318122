import anime, { AnimeAnimParams } from 'animejs';
import React from 'react';

import imgPhone from '../../../assets/imgs/homepage-section-7-phone.png';
import { googleConsentURL } from '../../../interfaces/backend/auth';
import { ObserveIntersect } from '../../../utilities/functions';
import { Button } from '../../_atoms/Button';
import { SVG } from '../../_atoms/SVG';
import { Spacer } from './Spacer';

export function Section7() {
  return (
    <section className="section-obs section-7">
      <Spacer />

      <section className="section-7-content">
        <article className="section-7-preview">
          <div className="phone-particles">
            <SVG
              name="phone-particle-1"
              className="phone-particle phone-particle-1 move-left"
            />
            <SVG
              name="phone-particle-1"
              className="phone-particle phone-particle-2 move-right"
            />
            <SVG
              name="phone-particle-1"
              className="phone-particle phone-particle-3 move-left"
            />
            <SVG
              name="phone-particle-2"
              className="phone-particle phone-particle-4 move-right"
            />
            <SVG
              name="phone-particle-2"
              className="phone-particle phone-particle-5 move-left"
            />
            <SVG
              name="phone-particle-2"
              className="phone-particle phone-particle-6 move-left"
            />

            <picture>
              <img src={imgPhone} alt="SmartDify downloadable Application" />
            </picture>
          </div>
        </article>

        <article className="section-7-description">
          <h3 className="description-title">
            Free download app for android and iphone
          </h3>

          <p className="description-content">
            Lorem ipsum dolor sit amet consectetur. Fringilla interdum dui enim
            volutpat lobortis dolor vulputate ut. Malesuada rhoncus bibendum
            massa
          </p>

          <div className="description-actions">
            <Button
              name="Start Free"
              onClick={() => {
                window.open(googleConsentURL, "_self");
              }}
            />
          </div>
        </article>
      </section>

      <Spacer />
    </section>
  );
}

const moveLeft: AnimeAnimParams = {
  translateX: [
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: -25, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: -25, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
  ],
  translateY: [
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: -5, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: -5, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
  ],
};

const moveRight: AnimeAnimParams = {
  translateX: [
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: 25, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: 25, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
  ],
  translateY: [
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: 10, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
    { value: 10, duration: 1000, delay: 0, easing: "linear" },
    { value: 0, duration: 1000, delay: 0, easing: "linear" },
  ],
};

export const observe7 = new ObserveIntersect(
  ".section-7",
  ([entry], observe) => {
    if (!entry.isIntersecting) return;
    observe.observer.unobserve(entry.target);

    anime.timeline().add({
      targets: ".section-7 .move-left",
      ...moveLeft,
    });

    anime.timeline().add({
      targets: ".section-7 .move-right",
      ...moveRight,
    });

    anime.timeline().add({
      targets: ".section-7-description",
      opacity: [0, 1],
      duration: 2000,
      easing: "linear",
      delay: 1000,
    });
  }
);
