import React from 'react';

import { signal } from '@preact/signals-react';

import { observeFooter } from '../../_organisms/home/Footer';
import { Section1 } from '../../_organisms/home/Section1';
import { observe2, Section2 } from '../../_organisms/home/Section2';
import { observe3, Section3 } from '../../_organisms/home/Section3';
import { observe5, Section5 } from '../../_organisms/home/Section5';
import { observe6, Section6 } from '../../_organisms/home/Section6';
import { observe7, Section7 } from '../../_organisms/home/Section7';
import { Spacer } from '../../_organisms/home/Spacer';

export function LayoutHome() {
  useObserve();

  return (
    <React.Fragment>
      <aside />
      <main className="homepage">
        <Section1 />
        <Spacer />
        <Section2 />
        <Spacer />
        <Section3 />
        <Spacer />
        {/* <Section4 />
        <Spacer /> */}
        <Section5 />
        <Spacer />
        <Section6 />
        <Spacer />
        <Section7 />
      </main>
    </React.Fragment>
  );
}

function useObserve() {
  React.useEffect(() => {
    initObserve();
  }, []);

  React.useEffect(() => {
    observe();
  }, []);
}

function initObserve() {
  observe2.init();
  observe3.init();
  // observe4.init();
  observe5.init();
  observe6.init();
  observe7.init();
  observeFooter.init();
}

function observe() {
  observe2.observe();
  observe3.observe();
  // observe4.observe();
  observe5.observe();
  observe6.observe();
  observe7.observe();
  observeFooter.observe();
}

export const isLoading = signal<boolean>(true);
