import { gqlGetProfile, gqlUpdateProfile } from '../../utilities/variables/queries/profile.gql';
import { GraphQL } from './_server';

export const getProfile = GraphQL.createQuery({
  document: gqlGetProfile,
  name: "getProfile",
});

export const updateProfile = GraphQL.createMutation({
  document: gqlUpdateProfile,
  name: "updateProfile",
});
