import type { Signal } from "@preact/signals-react";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type {
  GraphQLClientRequestHeaders,
  RequestConfig,
} from "graphql-request/src/types";

import { MD5 } from "crypto-js";
import { GraphQLClient, RequestDocument, Variables } from "graphql-request";
import { RemoveIndex } from "graphql-request/src/helpers";
import { clear, createStore, del, get, set, UseStore } from "idb-keyval";

import { signal } from "@preact/signals-react";

export enum STATUS {
  IDLE = "idle",
  LOADING = "loading",
  ERROR = "error",
  SUCCESS = "success",
}

// const client = new GraphQLClient("https://api.graphql.jobs/");

// client.request

// Ability to set interceptors for queries and mutations
// Retry on failed queries

/**
 * set data with cache on class constructor is not a not good idea because it is heavy on startup
 *
 * request => useCache : true => getCache => if stale time expired or no cache => parallel fetch
 *
 * request => useCache : false => ignore cache and only fetch
 */

// cache key : { doc, variables }
// cache value : { data, updatedAt }

// TODO: FIXME: onBefore, onAfter

class Request<T, V extends Variables = Variables> {
  constructor(
    private readonly _baseConfig: TypeRequestBaseConfig<T, V> &
      TypeCustomConfigs,
    private readonly _client: GraphQLClient
  ) {}

  public readonly updatedAt = signal(-1);

  public readonly data: Signal<T | undefined> = signal(undefined);

  public readonly statusNetwork = signal(STATUS.IDLE);
  public readonly statusCache = signal(STATUS.IDLE);
  public readonly errorCache = signal<any>(undefined);
  public readonly errorNetwork = signal<any>(undefined);

  private _variables: V | undefined = undefined;
  private _requestHeaders: GraphQLClientRequestHeaders | undefined = undefined;

  private readonly _cacheStore: UseStore | undefined = this._baseConfig.useCache
    ? createStore(this._baseConfig.name, this._baseConfig.rootName)
    : undefined;

  private getCacheKey(variables: V | undefined) {
    return MD5(
      JSON.stringify({
        document: this._baseConfig.document,
        variables: Object.entries(variables ?? "ALL").sort((a, b) =>
          a[0].localeCompare(b[0])
        ),
      })
    ).toString();
  }

  private _onErrorCache(error: any) {
    this.statusCache.value = STATUS.ERROR;
    this.errorCache.value = error;
  }

  private _onErrorNetwork(error: any) {
    this.statusNetwork.value = STATUS.ERROR;
    this.errorNetwork.value = error;
  }

  private _onSuccessNetwork() {
    this.statusNetwork.value = STATUS.SUCCESS;
    this.errorNetwork.value = undefined;
  }

  private async _getCache(
    cacheKey: string
  ): Promise<TypeCacheRecord<T> | undefined> {
    const before = Date.now();

    let cache: TypeCacheRecord<T> | undefined = undefined;

    try {
      this.statusCache.value = STATUS.LOADING;

      cache = this._cacheStore
        ? await get(cacheKey, this._cacheStore)
        : undefined;

      this.statusCache.value = STATUS.SUCCESS;
    } catch (error) {
      this._onErrorCache(error);
    }

    console.log(
      `${this._baseConfig.name} cache: ` + (Date.now() - before) + "ms"
    );

    return cache;
  }

  private async _setCache(cacheKey: string, value: TypeCacheRecord<T>) {
    try {
      this.statusCache.value = STATUS.LOADING;

      if (this._cacheStore) await set(cacheKey, value, this._cacheStore);

      this.statusCache.value = STATUS.SUCCESS;
    } catch (error) {
      console.error(error);
      this._onErrorCache(error);
    }
  }

  private async _delCache(cacheKey: string) {
    if (this._cacheStore) del(cacheKey, this._cacheStore);
  }
  private async _clearAllCache() {
    if (this._cacheStore) clear(this._cacheStore);
  }

  private async _requestWithCache(
    cacheKey: string,
    variables: V | undefined,
    requestHeaders: GraphQLClientRequestHeaders | undefined
  ) {
    this.statusCache.value = STATUS.LOADING;

    const cache = await this._getCache(cacheKey);

    if (!!cache) {
      this.data.value = cache.data;
      this.updatedAt.value = cache.updatedAt;
    }

    const isExpired =
      Date.now() - this.updatedAt.value > (this._baseConfig.staleTime ?? -1);

    let data: T | undefined = cache?.data;

    if (!cache || isExpired) {
      data = await this._requestBase(cacheKey, variables, requestHeaders);
    }

    return data as T;
  }

  private async _requestBase(
    cacheKey: string,
    variables: V | undefined,
    requestHeaders: GraphQLClientRequestHeaders | undefined
  ) {
    this.statusNetwork.value = STATUS.LOADING;

    const isSameCacheKey = cacheKey === this.getCacheKey(this._variables);

    const data = await this._client.request<T, V>(
      this._baseConfig.document,
      // @ts-ignore
      variables,
      requestHeaders
    );

    const updatedAt = Date.now();

    if (isSameCacheKey) {
      this.data.value = data;
      this.updatedAt.value = updatedAt;
    }

    if (this._baseConfig.useCache)
      this._setCache(cacheKey, { data, updatedAt });

    return data;
  }

  // if data is requested then it should be available right away therefore we can clear the cache immediately
  public async invalidate(configs?: {
    deleteCache?: boolean;
    clearState?: boolean;
    clearAllCache?: boolean;
    ignoreCache?: boolean;
  }) {
    const {
      deleteCache = false,
      clearAllCache = false,
      clearState = false,
      ignoreCache = true,
    } = configs ?? {};

    if (clearAllCache) this._clearAllCache();

    if (deleteCache) this._delCache(this.getCacheKey(this._variables));

    if (clearState) {
      this.data.value = undefined;
      this.updatedAt.value = -1;
    }

    // @ts-ignore
    return await this.request({
      variables: this._variables ? { ...this._variables } : undefined,
      requestHeaders: this._requestHeaders
        ? { ...this._requestHeaders }
        : undefined,

      ignoreCache,
    }).catch(() => {
      this.updatedAt.value = -1;
    });
  }

  // public async request(
  //   ...args: VariablesAndRequestHeadersArgs<V>
  // ): Promise<void> {
  //   const [variables, requestHeaders] = args;
  //   // @ts-ignore
  //   this._variables = { ...variables };
  //   this._requestHeaders = { ...requestHeaders };

  //   this.statusNetwork.value = STATUS.IDLE;
  //   this.statusCache.value = STATUS.IDLE;

  //   const cacheKey = this.getCacheKey(variables as V);

  //   try {
  //     await (!this._baseConfig.useCache
  //       ? this._requestBase(cacheKey, variables as V, requestHeaders)
  //       : this._requestWithCache(cacheKey, variables as V, requestHeaders));

  //     this._onSuccessNetwork();
  //   } catch (error) {
  //     this._onErrorNetwork(error);
  //   }
  // }

  public async request(...args: RequestProps<V, T>): Promise<void> {
    const [props] = args;
    const {
      variables,
      requestHeaders,
      onSuccess,
      onError,
      finally: onFinally,
      ignoreCache = false,
    } = props ?? {};
    // @ts-ignore
    this._variables = variables ? { ...variables } : undefined;
    this._requestHeaders = { ...requestHeaders };

    this.statusNetwork.value = STATUS.IDLE;
    this.statusCache.value = STATUS.IDLE;

    const cacheKey = this.getCacheKey(variables as V);

    const isSameCacheKey = cacheKey === this.getCacheKey(this._variables);

    try {
      const data = await (!this._baseConfig.useCache || ignoreCache
        ? this._requestBase(cacheKey, variables as V, requestHeaders)
        : this._requestWithCache(cacheKey, variables as V, requestHeaders));

      if (isSameCacheKey) this._onSuccessNetwork();

      await onSuccess?.(data)?.catch(console.error);
      await onFinally?.()?.catch(console.error);
    } catch (error) {
      if (isSameCacheKey) this._onErrorNetwork(error);

      try {
        // Should not be called more than once

        const isRefreshed = await this._baseConfig.refreshAccessToken?.(error);

        if (!isRefreshed) {
          await onError?.(error)?.catch(console.error);

          await onFinally?.()?.catch(console.error);

          return;
        }

        await this.request(...args);
      } catch (error) {
        console.error(error);
      }
    }
  }
}

export class GQL {
  constructor(
    private readonly _name: string,
    private readonly _url: string,
    private readonly _requestConfig: RequestConfig = {},
    private readonly _otherConfigs: TypeCustomConfigs = {}
  ) {}

  private readonly _client = new GraphQLClient(this._url, this._requestConfig);

  private _createRequest<T, V extends Variables = Variables>(
    configs: Omit<TypeRequestBaseConfig<T, V>, "rootName">
  ): Request<T, V> {
    return new Request<T, V>(
      {
        rootName: this._name,
        ...configs,
        ...this._otherConfigs,
      },
      this._client
    );
  }

  public createQuery<T, V extends Variables = Variables>(
    configs: Omit<TypeRequestBaseConfig<T, V>, "rootName" | "useCache">
  ) {
    return this._createRequest<T, V>({
      useCache: true,
      ...configs,
    });
  }

  public createMutation<T, V extends Variables = Variables>(
    configs: Omit<TypeRequestBaseConfig<T, V>, "rootName" | "useCache">
  ) {
    return this._createRequest<T, V>({ useCache: false, ...configs });
  }
}

// Types **************************************************************************
export type TypeCacheRecord<T> = {
  data: T;
  updatedAt: number;
};

export type TypeRequestBaseConfig<T, V extends Variables = Variables> = {
  name: string;
  rootName: string;
  staleTime?: number;
  // retry: number;
  document: RequestDocument | TypedDocumentNode<T, V>;
  useCache: boolean;
};

type TypeCustomConfigs = {
  refreshAccessToken?: (error: any) => Promise<boolean>;
};

type RequestProps<V extends Variables, T> = V extends Record<any, never>
  ? // do we have explicitly no variables allowed?
    [props?: { variables?: V } & TypeRequestConfigs<T>]
  : keyof RemoveIndex<V> extends never // do we get an empty variables object?
  ? [props?: { variables?: V } & TypeRequestConfigs<T>]
  : [props: { variables: V } & TypeRequestConfigs<T>];

type TypeRequestConfigs<T> = {
  requestHeaders?: GraphQLClientRequestHeaders;
  onSuccess?: (data: T) => any | Promise<any>;
  onError?: (error: any) => any | Promise<any>;
  finally?: () => any | Promise<any>;
  ignoreCache?: boolean;
};
