import type { JwtPayload } from "jwt-decode";
// import Cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { computed, signal } from "@preact/signals-react";

import {
  accessToken,
  lastAuthCheck,
  logout,
  refreshAccessToken,
  requestedRefreshToken,
} from "../../backend/auth";
import { closeModal } from "../modal";

export const auth = computed<TypeAuth | undefined>(() => {
  return accessToken.value ? jwtDecode<TypeAuth>(accessToken.value) : undefined;
});

export const isAuthValid = computed(() => {
  const isValid = !auth.value?.exp
    ? false
    : auth.value.exp * 1000 > lastAuthCheck.value;

  if (!isValid && auth.value?.exp) accessToken.value = undefined;

  return isValid;
});

export const isRefreshingAuth = signal<boolean>(false);

export function useAuth() {
  const { pathname } = useLocation();

  const isValid = isAuthValid.value;

  React.useEffect(() => {
    if (!isAuthValid.value) return;

    lastAuthCheck.value = Date.now();
  }, [pathname]);

  React.useEffect(() => {
    if (isValid) return;

    // const idToken = Cookie.get("idToken");
    // idToken does not exist and auth is not valid
    // if (!idToken) return;

    if (requestedRefreshToken.value > 0) return;

    // idToken exists but auth is not valid
    isRefreshingAuth.value = true;
    refreshAccessToken().finally(() => {
      isRefreshingAuth.value = false;
    });
  }, [isValid]);
}

export function useAuthProtect() {
  const navigate = useNavigate();

  const isValid = isAuthValid.value;

  React.useEffect(() => {
    if (isValid) return;

    // const idToken = Cookie.get("idToken");

    if (
      // !idToken
      requestedRefreshToken.value > 0
    ) {
      console.log("Clear Auth!");

      accessToken.value = undefined;
      isRefreshingAuth.value = false;
      navigate("/", { replace: true });
      toast.error("You are not authenticated");
      return;
    }
  }, [isValid, navigate]);

  return { auth, isAuthValid, isRefreshingAuth };
}

export function useSignOut() {
  const navigate = useNavigate();

  return React.useCallback(() => {
    closeModal();

    navigate("/", { replace: true });

    logout().then(() => {
      toast.success("Logged out");
    });
  }, [navigate]);
}

export type TypeAuth = JwtPayload & {
  authMethod?: "google" | "linkedin" | "facebook" | "credential";
  authorization?: string;
};
