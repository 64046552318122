import anime from 'animejs';
import React from 'react';

import { googleConsentURL } from '../../../interfaces/backend/auth';
import { Button } from '../../_atoms/Button';

export function Section1Heading({ className }: TypePropsSection1Heading) {
  React.useEffect(() => {
    const timeline = anime.timeline().add({
      targets: ".section-1-anime-container .word",
      scale: [10, 1],
      opacity: [0, 1],
      easing: "easeOutCirc",
      duration: 2000,
      delay: (el: any, i) => (i > 0 ? -500 : 100) + i * 2000,
    });
    // .add({
    //   targets: ".section-1-anime-container",
    //   opacity: 0,
    //   duration: 1000,
    //   easing: "easeOutExpo",
    //   delay: 1000,
    // });

    return function () {
      timeline.pause();
    };
  });

  return (
    <article className={className}>
      <h1 className="heading-name">Smartdify</h1>

      <h2 className="heading-services">
        Prepare your
        <br />
        <span className="section-1-anime-container">
          <span className="word">reports</span>&nbsp;
          <span className="word">using</span>&nbsp;
          <span className="word">AI</span>
        </span>
      </h2>

      <h3 className="heading-description">
        The trusted solution for home inspection report writing and business
        management tools
      </h3>

      <div className="heading-action">
        <Button
          onClick={() => {
            // refreshAccessToken();
            // navigate(googleConsentURL);
            window.open(googleConsentURL, "_self");
          }}
          name="Start Free"
        />
      </div>
    </article>
  );
}

export type TypePropsSection1Heading = {
  className?: string;
};
