export enum MODALS_TEMPLATES {
  SECTIONS = "sections",

  ITEMS = "items",

  OBSERVATIONS = "observations",
}

export enum MODALS_NAMES {
  ACCOUNT = "account",
  ADDRESS = "address",
  DATE_TIME_PICKER = "date-time-picker",
  SERVICES = "services",
  REAL_ESTATE_CATEGORY = "real-estate-category",
  NEW_INSPECTION_CLIENT_CATEGORY = "new-inspection-client-category",
  NEW_INSPECTION_SERVICE = "new-inspection-service",
  REPORT_CATEGORY_TAGS = "report-category-tags",
  DISCOUNT = "discount",
  TEMPLATE_UPLOAD = "template-upload",
  TEMPLATE_DELETE = "template-delete",
  INSPECTION_DETAILS_EDIT_SERVICES = "inspection-details-edit-services",
  INSPECTION_DETAILS_EDIT_CLIENT = "inspection-details-edit-client",
  INSPECTION_DETAILS_EDIT_AGENT = "inspection-details-edit-agent",
  INSPECTION_DETAILS_PAYMENT = "inspection-details-payment",
  INSPECTION_DETAILS_EDIT_PEOPLE = "inspection-details-edit-people",
  INSPECTION_DETAILS_INTERNALS = "inspection-details-internals",
  INSPECTION_DETAILS_UPLOAD_FILE = "inspection-details-upload-file",
  INSPECTION_DETAILS_CONFIRM_AGREEMENT = "inspection-details-confirm-agreement",
  INSPECTION_DETAILS_AGREEMENT_SETTINGS = "inspection-details-agreement-settings",
  INSPECTION_DETAILS_CLIENT_CATEGORY = "inspection-details-client-category",
  SETTINGS_UPLOAD_EXISTING_SIGNATURE = "settings-upload-existing-signature",
  CLIENT_VIEW_AGREEMENT = "client-view-agreement",
  CLIENT_VIEW_PAYMENT = "client-view-payment",
}
