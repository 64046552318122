import axios from "axios";
import { toast } from "react-toastify";

import { signal } from "@preact/signals-react";

import { InspectionQuery } from "../../../utilities/gql/graphql";
import { TypeClientViewAdditionalData } from "../../../utilities/types/clientView";
import { CONFIG } from "../../../utilities/variables/config";
import { clientUniquePath } from "../../states/client";

export const inspectionDataForClient = signal<
  | (InspectionQuery["inspection"] & TypeClientViewAdditionalData)
  | undefined
  | null
>(undefined);

export async function getInspectionDataForClient(params?: {
  onSuccess?: (data: any) => any | Promise<any>;
  onError?: (error: any) => any | Promise<any>;
}) {
  const { onSuccess, onError } = params || {};

  const enc = clientUniquePath.value;

  if (!enc) toast.error("Unique path not found");

  try {
    const data = (
      await axios.get(CONFIG.apiBaseUrl + "/client/inspection?enc=" + enc)
    )?.data;

    inspectionDataForClient.value = data;

    onSuccess?.(data)?.catch?.(console.error);
  } catch (error) {
    onError ? onError(error)?.catch?.(console.error) : console.error(error);
  }
}
