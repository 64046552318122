import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

import { SVG, TypePropsSVG } from "../../_atoms/SVG";

export function FooterCard({
  title,
  items,
  isVertical = true,
}: TypePropsFooterCard) {
  const uid = React.useId();

  return (
    <article className="homepage-footer-card">
      <h4 className="card-title">{title}</h4>

      <div
        className={clsx(
          "card-items",
          isVertical ? "card-items-vertical" : "card-items-horizontal"
        )}
      >
        {items.map(({ icon, label, navigate, onClick }, index) =>
          navigate ? (
            <NavLink to={navigate} key={index + uid} className="card-link">
              {label}
            </NavLink>
          ) : (
            <div
              onClick={onClick}
              className="card-item-container"
              key={index + uid}
            >
              {icon ? <SVG name={icon} className="card-icon" /> : null}

              {label ? <div className="card-label">{label}</div> : null}
            </div>
          )
        )}
      </div>
    </article>
  );
}

export type TypePropsFooterCard = {
  title: string;
  items: {
    label?: string;
    icon?: TypePropsSVG["name"];
    navigate?: string;
    onClick?: () => void;
  }[];
  isVertical?: boolean;
};
