import React from "react";
import { RingLoader } from "react-spinners";

export function SuspenseFallback({
  message = "Loading",
}: {
  message?: string;
}) {
  return (
    <React.Fragment>
      <aside></aside>
      <main className="layout-gc-start">
        <div className="layout-gc">
          <h6 className="suspense-fallback-title">{message}</h6>

          {/* <div className="suspense-fallback-loader">
            <HashLoader speedMultiplier={0.6} color="#7a41a8" />
          </div>

          <div className="suspense-fallback-loader">
            <PulseLoader speedMultiplier={0.25} color="#7a41a8" />
          </div> */}

          <div className="suspense-fallback-loader">
            <RingLoader speedMultiplier={0.65} color="#7a41a8" />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
