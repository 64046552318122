import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import { SuspenseFallback } from "./components/_organisms/SuspenseFallback";
import { AuthClient } from "./components/_templates/AuthClient";
import { AuthProtected } from "./components/_templates/AuthProtected";
import { LayoutBase } from "./components/_templates/LayoutBase";
import { Login } from "./components/pages/auth/Login";
import { Home } from "./components/pages/Home";
import { useWindowResize } from "./interfaces/states/window";
import {
  pwaInstallEvent,
  usePwaInstall,
} from "./utilities/hooks/usePwaInstall";
import { CONFIG } from "./utilities/variables/config";

console.log(
  `ENVIRONMENT: ${process.env.ENV ?? "NO ENV"} - ${
    process.env.REACT_APP_ENV ?? "NO REACT_APP_ENV"
  }`
);

console.log(`API URL: ${CONFIG.apiBaseUrl ?? "N.A."}`);

function App() {
  useWindowResize();

  usePwaInstall();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutBase />}>
          <Route index element={<Home />} />

          <Route element={<AuthProtected />}>
            <Route
              path="workspace"
              element={
                <React.Suspense fallback={<SuspenseFallback />}>
                  <Workspace />
                </React.Suspense>
              }
            />

            <Route path="reports">
              <Route
                index
                element={
                  <>
                    <aside></aside>
                    <main className="layout-gc-start">
                      <div></div>
                    </main>
                  </>
                }
              />

              <Route
                path="new"
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <Report />
                  </React.Suspense>
                }
              />
            </Route>

            <Route
              path="templates"
              element={
                <>
                  <aside></aside>
                  <main className="layout-gc-start">Templates</main>
                </>
              }
            />

            <Route
              path="contacts"
              element={
                <>
                  <aside></aside>
                  <main className="layout-gc-start">Contacts</main>
                </>
              }
            />

            <Route
              path="metrics"
              element={
                <>
                  <aside></aside>
                  <main className="layout-gc-start">Metrics</main>
                </>
              }
            />

            <Route
              path="automation"
              element={
                <>
                  <aside></aside>
                  <main className="layout-gc-start">Automation</main>
                </>
              }
            />

            <Route
              path="settings"
              element={
                <React.Suspense fallback={<SuspenseFallback />}>
                  <Settings />
                </React.Suspense>
              }
            />

            <Route path="inspections">
              <Route
                index
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <Inspections />
                  </React.Suspense>
                }
              />

              <Route
                path="details"
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <InspectionDetails />
                  </React.Suspense>
                }
              />

              <Route
                path="create-new-inspection"
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <CreateNewInspection />
                  </React.Suspense>
                }
              />

              <Route path="new">
                <Route
                  index
                  element={
                    <React.Suspense fallback={<SuspenseFallback />}>
                      <NewInspection />
                    </React.Suspense>
                  }
                />

                <Route
                  path="agent"
                  element={
                    <React.Suspense fallback={<SuspenseFallback />}>
                      <NewInspectionAgent />
                    </React.Suspense>
                  }
                />

                <Route
                  path="property"
                  element={
                    <React.Suspense fallback={<SuspenseFallback />}>
                      <NewInspectionProperty />
                    </React.Suspense>
                  }
                />

                <Route
                  path="client"
                  element={
                    <React.Suspense fallback={<SuspenseFallback />}>
                      <NewInspectionClient />
                    </React.Suspense>
                  }
                />
              </Route>
            </Route>

            <Route path="quotes">
              <Route path="new">
                <Route
                  index
                  element={
                    <React.Suspense fallback={<SuspenseFallback />}>
                      <NewQuote />
                    </React.Suspense>
                  }
                />
              </Route>
            </Route>

            <Route path="companies">
              <Route path="profiles">
                <Route path="new">
                  <Route
                    index
                    element={
                      <React.Suspense fallback={<SuspenseFallback />}>
                        <NewCompanyProfile />
                      </React.Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route path="inspectors">
              <Route path="profiles">
                <Route path="new">
                  <Route
                    index
                    element={
                      <React.Suspense fallback={<SuspenseFallback />}>
                        <NewInspectorProfile />
                      </React.Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route path="templates">
              <Route
                index
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <Templates />
                  </React.Suspense>
                }
              />

              <Route
                path="new"
                element={
                  <React.Suspense fallback={<SuspenseFallback />}>
                    <Template />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="client" element={<AuthClient />}>
            <Route
              path="inspection"
              element={
                <React.Suspense fallback={<SuspenseFallback />}>
                  <InspectionsClientView />
                </React.Suspense>
              }
            />

            <Route
              path="report"
              element={
                <React.Suspense fallback={<SuspenseFallback />}>
                  <ClientViewReport />
                </React.Suspense>
              }
            />

            <Route
              path="agreement"
              element={
                <>
                  <aside></aside>
                  <main className="layout-gc">Client Agreement</main>
                </>
              }
            />
          </Route>

          <Route path="test" element={<Test />} />

          <Route path="login" element={<Login />} />

          <Route
            path="about"
            element={
              <SuspenseFallback message="Loading About US Content From Server" />
            }
          />

          <Route
            path="contact"
            element={
              <SuspenseFallback message="Loading Contacts Content From Server" />
            }
          />

          <Route
            path="pricing"
            element={
              <SuspenseFallback message="Loading Pricing Content From Server" />
            }
          />

          <Route
            path="*"
            element={
              <>
                <aside></aside>
                <main className="layout-gc">Not Found</main>
              </>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const Settings = React.lazy(() => import("./components/pages/Settings"));
const NewCompanyProfile = React.lazy(
  () => import("./components/pages/companies/profiles/New")
);
const Inspections = React.lazy(
  () => import("./components/pages/inspections/Inspections")
);

const NewInspectionAgent = React.lazy(
  () => import("./components/pages/inspections/new/Agent")
);
const NewInspectionClient = React.lazy(
  () => import("./components/pages/inspections/new/Client")
);

const NewInspection = React.lazy(
  () => import("./components/pages/inspections/new/New")
);

const NewInspectorProfile = React.lazy(
  () => import("./components/pages/inspectors/profiles/New")
);

const NewQuote = React.lazy(() => import("./components/pages/quotes/New"));
const Workspace = React.lazy(() => import("./components/pages/Workspace"));

const NewInspectionProperty = React.lazy(
  () => import("./components/pages/inspections/new/Property")
);

const Template = React.lazy(() => import("./components/pages/templates/New"));

const Templates = React.lazy(
  () => import("./components/pages/templates/Templates")
);

const Report = React.lazy(() => import("./components/pages/reports/New"));

const InspectionDetails = React.lazy(
  () => import("./components/pages/inspections/Details")
);

const InspectionsClientView = React.lazy(
  () => import("./components/pages/inspections/ClientView")
);

const ClientViewReport = React.lazy(
  () => import("./components/pages/clientView/Report")
);

const CreateNewInspection = React.lazy(
  () => import("./components/pages/inspections/CreateNewInspection")
);

// ---------------------------------------------------------------------------------------------

function Test() {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <aside></aside>
      <main className="layout-gc wh-fill">
        <div className="w-fill layout-gc-start">
          <div>Test</div>
          <div style={{ width: "60rem", height: "60rem" }}>
            <button
              onClick={() => {
                console.log({ pwa: pwaInstallEvent.value });
                pwaInstallEvent.value?.prompt();
              }}
            >
              Check Request URL
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
