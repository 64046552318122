import type { NavLinkProps } from "react-router-dom";

import clsx from 'clsx';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const Links = React.memo(LinksRaw);
function LinksRaw({ links = [], ...generalProps }: TypePropsLinks) {
  const uid = React.useId();

  const location = useLocation();
  console.log({ location });

  return (
    <React.Fragment>
      {links.map(({ label, ...itemProps }, index) => (
        <NavLink
          className={({ isActive }) =>
            clsx(isActive && location.pathname === itemProps.to && "active")
          }
          {...generalProps}
          {...itemProps}
          key={index + uid}
        >
          {label}
        </NavLink>
      ))}
    </React.Fragment>
  );
}

export type TypePropsLinks = {
  links: ({ label: string } & Omit<NavLinkProps, "children">)[];
} & Omit<NavLinkProps, "children" | "to">;
