import React from "react";

import { signal } from "@preact/signals-react";

export const modalStatus = signal<{
  openModalName: string | undefined | null;
  mode: TypeModalMode;
}>({
  openModalName: undefined,
  mode: "other",
});

export function closeModal() {
  modalStatus.value = {
    mode: "other",
    openModalName: undefined,
  };
}

export function openModal({
  mode = "other",
  name: openModalName,
}: {
  name: string;
  mode?: TypeModalMode;
}) {
  modalStatus.value = { mode, openModalName };
}

export function useModalRoot(): HTMLElement | null {
  const [modalRoot, setModalRoot] = React.useState<HTMLElement | null>(null);

  React.useEffect(() => {
    setModalRoot(getModalRoot());
  }, []);

  return modalRoot;
}

function getModalRoot(rootId: string = "modal-root") {
  const mRoot = document.getElementById(rootId);
  if (mRoot) return mRoot;

  const newRoot = document.createElement("div");
  newRoot.setAttribute("id", rootId);
  document.body.appendChild(newRoot);
  return newRoot;
}

//
export type TypeModalMode = "add" | "edit" | "view" | "other";
