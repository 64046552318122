import { toast } from "react-toastify";

import { signal } from "@preact/signals-react";

import { login } from "../../backend/auth";

export const loginUsername = signal<string | undefined>("");
export const loginPassword = signal<string | undefined>("");

//
export function onChangeUsername(newValue: string | undefined) {
  loginUsername.value = newValue;
}

export function onChangePassword(newValue: string | undefined) {
  loginPassword.value = newValue;
}

export function onFormLogin(props?: {
  onSuccess?: () => void | Promise<void>;
  onError?: (error: Error) => void | Promise<void>;
}) {
  const { onSuccess, onError } = props ?? {};
  const email = loginUsername.value;
  const password = loginPassword.value;

  if (!email || !password) return toast.error("No credentials provided");

  login({
    email,
    password,
    onSuccess,
    onError,
  });
}
