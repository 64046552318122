import Cookie from "js-cookie";

import { GQL } from "../../utilities/classes/gql";
import { CONFIG } from "../../utilities/variables/config";
import { accessToken, refreshAccessToken } from "./auth";

const url = CONFIG.apiBaseUrl + "/graph";

export const GraphQL = new GQL(
  "GQL",
  url,
  {
    requestMiddleware: async (req) => {
      req.headers = {
        ...req.headers,
        Authorization: `Bearer ${accessToken.value ?? ""}`,
      };
      return req;
    },

    // responseMiddleware: async (res) => {
    //   console.log("GQL Response\n", { res });
    //   return res;
    // },
  },
  {
    refreshAccessToken: onRefreshToken,
  }
);

async function onRefreshToken(error: any): Promise<boolean> {
  try {
    const status = error?.response?.status;

    // to prevent infinite loop
    const idToken = Cookie.get("idToken");

    if (status !== 401 || !idToken) return false;

    await refreshAccessToken();

    return !!accessToken.value;
  } catch (error) {
    return false;
  }
}
