import anime from "animejs";
import React from "react";

import imgBuilding from "../../../assets/imgs/homepage-section-5.png";
import { ObserveIntersect } from "../../../utilities/functions";
import { SVG } from "../../_atoms/SVG";

export function Section5() {
  return (
    <section className="section-obs section-5">
      <SVG name="dots" className="section-5-dots" />

      <section className="section-5-content">
        <article className="section-5-preview">
          <picture>
            <img src={imgBuilding} alt="AI Inspection Media Processing" />
          </picture>
        </article>

        <article className="section-5-description">
          <h3 className="description-title">Image processing</h3>

          <p className="description-content">
            In the realm of home inspections, leveraging image processing within
            report generation proves invaluable for inspectors. Utilizing a
            sophisticated report generator platform equipped with image analysis
            capabilities allows inspectors to uncover an observe potential
            issues that might have otherwise gone unnoticed. This innovative
            approach not only streamlines the inspection process but also
            ensures a more thorough and reliable assessment, ultimately
            contributing to the overall efficiency and effectiveness of home
            inspections.
          </p>
        </article>
      </section>

      <div className="section-5-colored-bg" />
    </section>
  );
}

export const observe5 = new ObserveIntersect(
  ".section-5",
  ([entry], observe) => {
    if (!entry.isIntersecting) return;

    observe.observer.unobserve(entry.target);

    anime.timeline().add({
      targets: ".section-5-dots",
      marginLeft: ["-15rem", "-9rem"],
      easing: "linear",
      duration: 1000,
      delay: 750,
    });

    anime.timeline().add({
      targets: ".section-5-preview",
      opacity: [0, 1],
      translateY: ["-5rem", "0rem"],
      easing: "linear",
      duration: 1500,
      delay: 750,
    });

    anime
      .timeline()
      .add({
        targets: ".section-5-colored-bg",
        translateX: ["100%", "0%"],
        easing: "linear",
        duration: 2000,
        delay: 750,
      })
      .add({
        targets: ".section-5-description",
        opacity: [0, 1],
        easing: "linear",
        duration: 1500,
      });
  }
);
