import anime from "animejs";
import React from "react";

import { ObserveIntersect } from "../../../utilities/functions";
import {
  Section6Card,
  TypePropsSection6Card,
} from "../../_molecules/home/Section6Card";

export function Section6() {
  const uid = React.useId();

  return (
    <section className="section-obs section-6">
      <div className="section-6-colored-bg" />

      <section className="section-6-content">
        <article className="section-6-description">
          <h3 className="description-title">All-encompassing</h3>

          <p className="description-content">
            Your Comprehensive Solution for Inspection Report Generation. Go
            from getting quotes to issue different type of reports, all within
            your inspection office.
          </p>
        </article>

        <section className="section-6-items">
          {services.map((item, index) => (
            <Section6Card {...item} key={index + uid} />
          ))}
        </section>
      </section>
    </section>
  );
}

const services: TypePropsSection6Card[] = [
  {
    icon: "task",
    label: "Report generator",
  },

  {
    icon: "note-sticky",
    label: "Various templates",
  },

  {
    icon: "schedule",
    label: "Scheduling options",
  },

  {
    icon: "money-2",
    label: "Quotes and estimates",
  },

  {
    icon: "edit-2",
    label: "Personal Agreements",
  },

  {
    icon: "doc-2",
    label: "Standard of Practice",
  },
];

export const observe6 = new ObserveIntersect(
  ".section-6",
  ([entry], observe) => {
    if (!entry.isIntersecting) return;
    observe.observer.unobserve(entry.target);

    anime
      .timeline()
      .add({
        targets: ".section-6-colored-bg",
        translateX: ["-100%", "0%"],
        easing: "linear",
        duration: 2000,
        delay: 750,
      })
      .add({
        targets: ".section-6-description",
        opacity: [0, 1],
        easing: "linear",
        duration: 1500,
      })
      .add({
        targets: ".section-6-card",
        opacity: [0, 1],
        easing: "linear",
        duration: 1000,
        delay: (el, index) => index * 1000,
      });
  }
);
