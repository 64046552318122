import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../../interfaces/states/auth/auth';
import { routerLocation } from '../../interfaces/states/routerLocation';
import { FooterMobile } from '../_molecules/mobileView/FooterMobile';
import { NavBar } from '../_molecules/NavBar';
import { FooterHome } from '../_organisms/home/Footer';

export function LayoutBase() {
  const { pathname, search } = useLocation();

  React.useEffect(() => {
    console.log({ pathname, search });

    routerLocation.value = { pathname, search };
  }, [pathname, search]);

  useAuth();

  return (
    <React.Fragment>
      <NavBar />

      <section className="layout-main-contents">
        <Outlet />
      </section>
      <footer className="footer">
        {pathname === "/" ? <FooterHome /> : <FooterMobile />}
      </footer>

      <Modals />
    </React.Fragment>
  );
}

//
const Modals = React.lazy(() => import("../_organisms/Modals"));
