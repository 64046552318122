import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  loginPassword,
  loginUsername,
  onChangePassword,
  onChangeUsername,
  onFormLogin,
} from "../../../interfaces/states/auth/login";
import { Button } from "../../_atoms/Button";
import { InputText } from "../../_atoms/InputText";

export function LayoutLogin() {
  const navigate = useNavigate();

  const onLogin = React.useCallback(() => {
    onFormLogin({
      onSuccess: () => {
        toast.success("Logged in");
        navigate("/workspace");
      },

      onError: () => {
        toast.error(`Error logging`);
      },
    });
  }, [navigate]);

  const username = loginUsername.value;
  const password = loginPassword.value;

  return (
    <React.Fragment>
      <aside />
      <main>
        <section
          className="layout-gc"
          style={{
            width: "30rem",
          }}
        >
          <InputText
            text={username}
            placeholder="Username"
            className="settings-input-text"
            setText={onChangeUsername}
          />

          <InputText
            text={password}
            placeholder="Password"
            className="settings-input-text"
            setText={onChangePassword}
          />

          <div>
            <Button name="Login" onClick={onLogin} />
          </div>
        </section>
      </main>
    </React.Fragment>
  );
}

//
