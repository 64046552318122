import anime from "animejs";
import React from "react";

import { ObserveIntersect } from "../../../utilities/functions";
import { SVG } from "../../_atoms/SVG";
import { Section3Description } from "../../_molecules/home/Section3Description";
import { Section3Payment } from "../../_molecules/home/Section3Payment";

export function Section3() {
  return (
    <section className="section-obs section-3">
      <SVG name="dots" className="section-3-dots" />

      <section className="section-3-content">
        <Section3Payment />
        <Section3Description />
      </section>

      <div className="section-3-colored-bg" />
    </section>
  );
}

export const observe3 = new ObserveIntersect(
  ".section-3",
  ([entry], observe) => {
    if (!entry.isIntersecting) return;

    observe.observer.unobserve(entry.target);

    anime.timeline().add({
      targets: ".section-3-dots",
      marginLeft: ["-15rem", "-9rem"],
      easing: "linear",
      duration: 1000,
      delay: 750,
    });

    anime.timeline().add({
      targets: ".section-3-payment",
      opacity: [0, 1],
      translateY: ["-5rem", "0rem"],
      easing: "linear",
      duration: 1500,
      delay: 750,
    });

    anime
      .timeline()
      .add({
        targets: ".section-3-colored-bg",
        translateX: ["100%", "0%"],
        easing: "linear",
        duration: 2000,
        delay: 750,
      })
      .add({
        targets: ".section-3-description",
        opacity: [0, 1],
        easing: "linear",
        duration: 1500,
      });
  }
);
