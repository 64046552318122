import { graphql } from '../../gql';

export const gqlGetProfile = graphql(`
  query Profile($where: IQProfile!) {
    profile(where: $where) {
      id
      isHidden
      credit
      email
      name
      phone
      picture
      website
      linkedin
      facebook
      address {
        id
        label
        formattedAddress
        addressTypes
        geo {
          lat
          lng
        }
      }
      company {
        id
        name
        email
        phone
        logo
        website
        address {
          id
          label
          formattedAddress
          addressTypes
          geo {
            lat
            lng
          }
        }
      }
    }
  }
`);

export const gqlUpdateProfile = graphql(`
  mutation UpdateProfile($where: IMProfileUpdate!) {
    updateProfile(where: $where) {
      id
    }
  }
`);
