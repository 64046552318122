import type { TypePropsSVG } from "./SVG";

import { clsx } from 'clsx';
import React from 'react';
import { PulseLoader } from 'react-spinners';

import { SVG } from './SVG';

export function Button<TypeData extends Record<string, any>>({
  logo,
  logoAfter,
  className,
  data,
  onClick,
  name,
  disabled = false,
  isProcessing = false,
  processingColor = "white",
  onBeforeClick,
  onAfterClick,

  style,
}: TypePropsButton<TypeData>) {
  const onClickHandler = React.useCallback(
    async (data?: TypeData) => {
      await onBeforeClick?.(data);
      await onClick?.(data);
      await onAfterClick?.(data);
    },
    [onClick, onBeforeClick, onAfterClick]
  );

  return (
    <button
      className={clsx(
        "btn",
        "layout-gr w-fill",
        className === undefined && "btn-primary",
        className
      )}
      onClick={() => onClickHandler(data)}
      disabled={disabled || isProcessing}
      style={{ cursor: disabled ? "not-allowed" : "pointer", ...style }}
      type="button"
    >
      {logo ? <SVG name={logo} /> : null}
      {name ? <span>{name}</span> : null}
      {isProcessing ? (
        <PulseLoader
          color={processingColor}
          className="icon-processing"
          size="0.5rem"
          speedMultiplier={0.65}
        />
      ) : null}
      {logoAfter ? <SVG name={logoAfter} /> : null}
    </button>
  );
}

export type TypePropsButton<TypeData extends Record<string, any>> = {
  logo?: TypePropsSVG["name"];
  logoAfter?: TypePropsSVG["name"];
  className?: string | null;
  data?: TypeData;
  onClick?: (data?: TypeData) => any | Promise<any>;
  onBeforeClick?: (data?: TypeData) => void | Promise<void>;
  onAfterClick?: (data?: TypeData) => void | Promise<void>;
  name?: string;
  disabled?: boolean;
  isProcessing?: boolean;
  processingColor?: string;

  style?: React.HTMLAttributes<HTMLButtonElement>["style"];
};
