import React from "react";

import { Section1Heading } from "../../_molecules/home/Section1Heading";
import { Section1Preview } from "../../_molecules/home/Section1Preview";

export function Section1() {
  return (
    <section className="section-obs section-1">
      <div className="section-1-colored-bg" />
      <section className="section-1-content">
        <Section1Heading className="section-1-heading" />
        <Section1Preview className="section-1-preview" />
      </section>
    </section>
  );
}
