import React from "react";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import { getInspectionDataForClient } from "../../interfaces/backend/clientView/inspection";
import { useClientAuth } from "../../interfaces/states/client";

export function AuthClient() {
  const enc = useClientAuth();

  const isAuthAvailable = !!enc;

  React.useEffect(() => {
    if (!isAuthAvailable) return;

    getInspectionDataForClient({
      onError() {
        toast.error("Failed to get inspection data");
      },

      onSuccess() {
        toast.success("Inspection data loaded");
      },
    });
  }, [isAuthAvailable]);

  return <Outlet />;
}
