import React from "react";
import { Outlet } from "react-router-dom";

import { useAuthProtect } from "../../interfaces/states/auth/auth";
import { SuspenseFallback } from "../_organisms/SuspenseFallback";

export function AuthProtected() {
  const { isAuthValid, isRefreshingAuth } = useAuthProtect();

  return !isAuthValid.value && !isRefreshingAuth.value ? (
    <SuspenseFallback message="Authenticating" />
  ) : (
    <Outlet />
  );

  // return <Outlet />;
}
