import React from "react";

export function Section3Payment() {
  return (
    <article className="section-3-payment">
      <div className="payment-prices price-main">
        <h2 className="price-main-title" aria-label="price">
          {"5$"}
        </h2>

        <h3 className="price-main-subtitle">Per inspection</h3>
      </div>

      <div className="payment-prices price-sub">
        <h3 className="price-sub-title" aria-label="price">
          {"Up to 75$/Mon"}
        </h3>

        <h4 className="price-sub-subtitle">Each inspector</h4>
      </div>
    </article>
  );
}
