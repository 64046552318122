import Cookie from "js-cookie";

import { effect, signal } from "@preact/signals-react";

import { CONFIG } from "../../utilities/variables/config";

const refreshURL = CONFIG.apiBaseUrl + "/auth/refresh";
const logoutURL = CONFIG.apiBaseUrl + "/auth/logout";
const loginCredentialsURL = CONFIG.apiBaseUrl + "/auth/login";

export const googleConsentURL = CONFIG.apiBaseUrl + "/oauth/google/consent";

export const lastAuthCheck = signal<number>(Date.now());

export const accessToken = signal<string | undefined>(undefined);

export const requestedRefreshToken = signal(0);

effect(() => {
  const tkn = accessToken.value;

  if (tkn) requestedRefreshToken.value = 0;

  console.log(`Bearer ${tkn ?? "***"}`);
});

export async function refreshAccessToken() {
  try {
    const { accessToken: newToken } = await (
      await fetch(refreshURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      })
    ).json();

    accessToken.value = newToken;

    requestedRefreshToken.value = 0;
  } catch (error) {
    Cookie.remove("idToken");
    accessToken.value = undefined;
    requestedRefreshToken.value = requestedRefreshToken.value + 1;
    console.error(error);
    lastAuthCheck.value = Date.now();
  }
}

export async function logout() {
  try {
    await (
      await fetch(logoutURL, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      })
    ).json();

    accessToken.value = undefined;
  } catch (error) {
    console.error(error);
  }
}

export async function login({
  email,
  password,

  onSuccess,
  onError,
}: {
  email: string;
  password: string;

  onSuccess?: () => void | Promise<void>;
  onError?: (error: Error) => void | Promise<void>;
}) {
  try {
    accessToken.value = (
      await (
        await fetch(loginCredentialsURL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        })
      ).json()
    ).accessToken;

    onSuccess?.()?.catch(console.error);
  } catch (error: any) {
    console.error(error);

    onError?.(error)?.catch(console.error);
  }
}
